@import '../base/settings';
.divider {
    border-bottom: solid 1px #6ec2fb; 
}
.ratesContainer {
    display: flex;
    justify-content: space-between;
}
.tableText {
    font-size: 16px;
    line-height: 28px;
    color: #515151;
}
.tableTextMobile {
    font-size: 15px;
    line-height: 28px;
    color: #515151;
}
.titleText {
    font-family: 'Roboto';
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    color: #082f57;
}
.titleHightlightText {
    font-weight: 500;
    color: #00a1da;
}
.link {
    color: #5AC2E7;
    font-weight: 500;
    text-transform: none;
    text-decoration: none;
}

@media (min-width: 600px) {
    .ratesContainer {
        width: 40%;
    }
}

@media (max-width: 600px) {
    .ratesContainer {
        width: 100%;
    }
}
